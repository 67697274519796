export * from "ce/pages/Editor/IDE/EditorPane/JS/utils/getJSEntityItemUrl";

import { getJSEntityItemUrl as CE_getJSEntityItemUrl } from "ce/pages/Editor/IDE/EditorPane/JS/utils/getJSEntityItemUrl";
import type { EntityItem } from "ee/entities/IDE/constants";
import { moduleInstanceEditorURL } from "ee/RouteBuilder";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";

export const getJSEntityItemUrl = (
  item: EntityItem,
  basePageId: string,
): string => {
  if (item.isModuleInstance) {
    return moduleInstanceEditorURL({
      basePageId,
      moduleType: MODULE_TYPE.JS,
      moduleInstanceId: item.key,
    });
  }

  return CE_getJSEntityItemUrl(item, basePageId);
};
