import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import produce from "immer";
import { createImmerReducer } from "utils/ReducerUtils";

export interface GenerateWorfklowApprovalPageState {
  isOpen: boolean;
}

export interface WorkflowPluginState {
  generateApprovalPageModalState: GenerateWorfklowApprovalPageState;
}

const initialState: WorkflowPluginState = {
  generateApprovalPageModalState: { isOpen: false },
};

export const handlers = {
  [ReduxActionTypes.SHOW_GENERATE_APPROVAL_PAGE_MODAL]: (
    state: WorkflowPluginState,
  ) => {
    return produce(state, (draft) => {
      draft.generateApprovalPageModalState.isOpen = true;
    });
  },
  [ReduxActionTypes.HIDE_GENERATE_APPROVAL_PAGE_MODAL]: (
    state: WorkflowPluginState,
  ) => {
    return produce(state, (draft) => {
      draft.generateApprovalPageModalState.isOpen = false;
    });
  },
};

const workflowPluginReducer = createImmerReducer(initialState, handlers);

export default workflowPluginReducer;
