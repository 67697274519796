import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { RagDocument } from "ee/components/formControls/RagDocuments/types";

export const setRagDocuments = (payload: {
  [kye: string]: RagDocument[];
}): ReduxAction<{ [kye: string]: RagDocument[] }> => ({
  type: ReduxActionTypes.SET_RAG_DOCUMENTS,
  payload,
});
